<template>
  <v-row justify="space-between">
    <v-col>
      <v-expansion-panels
        v-if="canSeeProjects"
        v-model="openPanels.projects"
        accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template #actions>
              <v-icon
                dense
                class="order-first mr-4">
                $expand
              </v-icon>
            </template>
            <div class="order-last d-flex justify-space-between align-center">
              <h2
                class="h4"
                data-cy="favorite-projects">
                {{ $t('favoriteProjects') }}
              </h2>
              <CyButton
                theme="primary"
                variant="tertiary"
                sm
                :to="{
                  name: 'projects',
                  params: {
                    orgCanonical,
                  },
                }"
                @click.stop>
                {{ $t('viewAllProjects') }}
              </CyButton>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <transition
              name="fade-transition"
              tag="div"
              mode="out-in">
              <div
                v-if="!isFirstLoadComplete.projects"
                key="projectCardSkeleton"
                class="grid grid--favorite-projects mt-4 pb-12">
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                  class="bordered rounded-lg"/>
              </div>
              <transition-group
                v-else
                key="projectsGrid"
                name="slide-y-transition"
                tag="div"
                class="grid grid--favorite-projects mt-4 pb-12">
                <CyProjectMiniCard
                  v-for="(project) in favoriteProjects"
                  :key="project.id"
                  :project="project"
                  :can-edit="!loadingProjects"
                  @unfavorited="getProjects"/>
                <CyAddFavoriteProject
                  key="favProjectAdd"
                  button-class="width-100 height-100"
                  data-cy="add-fav-project"
                  @favorited="getProjects"/>
              </transition-group>
            </transition>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels
        v-if="canSeeCloudCostManagement"
        v-model="openPanels.finops"
        class="finops-panel"
        accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template #actions>
              <v-icon
                dense
                class="order-first mr-4">
                $expand
              </v-icon>
            </template>
            <div class="order-last d-flex justify-space-between align-center">
              <h2 class="h4">
                {{ $t('finopsSectionTitle') }}
              </h2>
              <div>
                {{ finopsDateLabel }}
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pb-12">
            <transition
              name="fade-transition"
              tag="div"
              mode="out-in">
              <v-row>
                <v-col>
                  <CyCloudCostManagementWidgetsProviderBreakdown
                    :loading="!isFirstLoadComplete.finops"/>
                </v-col>
                <v-col>
                  <CyCloudCostManagementWidgetsEmissionsOverview
                    :loading="!isFirstLoadComplete.finops"/>
                </v-col>
              </v-row>
            </transition>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels
        v-if="canSeeKPIs"
        v-model="openPanels.metrics"
        accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template #actions>
              <v-icon
                dense
                class="order-first mr-4">
                $expand
              </v-icon>
            </template>
            <div class="order-last d-flex justify-space-between align-center">
              <h2
                class="h4"
                data-cy="favorite-metrics">
                {{ $t('favoriteMetrics') }}
              </h2>
              <div class="expansion-panel__filters">
                <CyInputsDateTimeRange
                  :relative-options="$static.kpiDateTimeFilterOptions"
                  :value="kpiFilters.datePeriod.range || kpiFilters.datePeriod.value"
                  future-dates-disabled
                  @change="handleKpiDateTimeFilterChange">
                  <template #activator="{ on, label }">
                    <CyButton
                      theme="primary"
                      variant="tertiary"
                      sm
                      v-on="on">
                      {{ label }}
                      <v-icon
                        dense
                        class="ml-1 mr-n2">
                        arrow_drop_down
                      </v-icon>
                    </CyButton>
                  </template>
                </CyInputsDateTimeRange>
                <CyMenu
                  :items="kpiProjectFilterOptions"
                  max-height="340"
                  offset-y
                  left>
                  <template #activator="{ attrs, on }">
                    <CyButton
                      v-bind="attrs"
                      theme="primary"
                      variant="tertiary"
                      sm
                      icon="mdi-filter"
                      class="ml-3"
                      v-on="on">
                      {{ $t('projectName', [_.$get(kpiFilters, 'project', 'All')]) }}
                      <v-icon
                        dense
                        class="ml-1 mr-n2">
                        arrow_drop_down
                      </v-icon>
                    </CyButton>
                  </template>
                </CyMenu>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <transition
              name="fade-transition"
              tag="div"
              mode="out-in">
              <div
                v-if="!isFirstLoadComplete.KPIs"
                key="KPICardSkeleton"
                class="grid grid--favorite-metrics mt-4">
                <v-skeleton-loader
                  type="card-heading,list-item-three-line,image,list-item"
                  class="bordered"/>
              </div>
              <transition-group
                v-else
                key="KPIsGrid"
                name="slide-y-transition"
                tag="div"
                class="grid grid--favorite-metrics mt-4">
                <CyKpiWidgetsDashboardWidget
                  v-for="KPI in displayedKPIs"
                  :key="KPI.canonical"
                  :kpi="KPI"
                  :loading="loadingKPIs"
                  :can-edit="isEditEnabled.KPIs"
                  class="mb-0"
                  @unfavorite="handleUnfavoriteStart"
                  @unfavorited="handleUnfavoriteEnd"/>
                <v-card
                  key="favKpisAdd"
                  outlined
                  rounded="lg"
                  class="kpi-widget-new cy-card cy-card--dashed-border">
                  <v-card-text class="d-flex align-center justify-center height-100 text-center">
                    <span class="kpi-widget-new__label">{{ $t('addFavoriteKpi') }}</span>
                  </v-card-text>
                </v-card>
              </transition-group>
            </transition>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col
      cols="auto"
      class="dashboard-sidebar overflow-hidden">
      <CyEventsRecentActivityCard
        v-has-rights-to="'GetEvents'"
        :loading="loadingEvents"
        :disabled="loadingEvents"
        :events="events"
        :all-events-route="{ name: 'events', params: { orgCanonical } }"
        :event-filters.sync="eventFilters"
        :event-props="eventProps"/>
      <CyKeyNumbersCard
        :organization-name="orgName"
        :organization-canonical="orgCanonical"
        :stats="summary"
        :loading="loadingSummary"
        class="mt-6"/>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CyAddFavoriteProject from '@/components/add-favorite-project.vue'
import CyCloudCostManagementWidgetsEmissionsOverview from '@/components/cloud-cost-management/widgets/emissions-overview.vue'
import CyCloudCostManagementWidgetsProviderBreakdown from '@/components/cloud-cost-management/widgets/provider-breakdown.vue'
import CyEventsRecentActivityCard from '@/components/events/recent-activity-card.vue'
import CyInputsDateTimeRange from '@/components/inputs/date-time-range.vue'
import CyKeyNumbersCard from '@/components/key-numbers-card.vue'
import CyKpiWidgetsDashboardWidget from '@/components/kpi-widgets/dashboard-widget.vue'
import CyProjectMiniCard from '@/components/project-mini-card.vue'
import { constructBreadcrumb } from '@/utils/helpers'

const defaultEventProps = {
  itemsPerPage: 50,
  sortBy: ['timestamp'],
  sortDesc: [true],
}

export default {
  name: 'CyPageDashboard',
  components: {
    CyCloudCostManagementWidgetsProviderBreakdown,
    CyCloudCostManagementWidgetsEmissionsOverview,
    CyProjectMiniCard,
    CyAddFavoriteProject,
    CyKpiWidgetsDashboardWidget,
    CyKeyNumbersCard,
    CyInputsDateTimeRange,
    CyEventsRecentActivityCard,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.dashboard'))
  },
  header () {
    return {
      title: this.$t('routes.dashboard'),
      description: {
        text: this.$t('routes.dashboardDescription'),
      },
    }
  },
  data: () => ({
    // The expanded state of panels is controlled by passing
    // a zero-based index of the currently opened expansion panel
    // to the parent "v-expansion-panels" component.
    openPanels: {
      projects: 0,
      finops: 0,
      metrics: 0,
    },
    isFirstLoadComplete: {
      projects: false,
      KPIs: false,
      events: false,
      finops: false,
    },
    isEditEnabled: {
      KPIs: true,
    },
    favoriteProjects: [],
    unfilteredKPIs: [],
  }),
  computed: {
    ...mapGetters('layout', [
      'getDataTableProps',
      'getDataTableFilters',
    ]),
    ...mapState('organization', {
      loadingSummary: (state) => state.fetchInProgress.summary,
      summary: (state) => state.summary,
      loadingProjects: (state) => state.fetchInProgress.projects,
      projects: (state) => state.available.projects,
      loadingEvents: (state) => state.fetchInProgress.events,
      events: (state) => state.available.events,
    }),
    ...mapState('organization/project/kpi', {
      KPIs: (state) => state.favoriteKPIs,
      loadingKPIs: (state) => state.isFetchInProgress.favoriteKPIs,
      kpiFilters: (state) => state.filtersFavorites,
    }),
    ...mapGetters([
      'isOrgSelected',
    ]),
    $static () {
      return {
        kpiDateTimeFilterOptions: [
          {
            label: this.$tc('forms.field.timeRangeLastDays', 1),
            value: '1d',
          },
          {
            label: this.$t('forms.field.timeRangeLastWeek'),
            value: '1w',
          },
          {
            label: this.$tc('forms.field.timeRangeLastMonths', 1),
            value: '1M',
          },
          {
            label: this.$tc('forms.field.timeRangeLastMonths', 3, { months: 3 }),
            value: '3M',
          },
          {
            label: this.$t('forms.field.timeRangeLastYear'),
            value: '1y',
          },
        ],
      }
    },
    eventProps: {
      get () {
        return this.getDataTableProps(this.$route.name)
      },
      set (props) {
        this.SET_DATA_TABLE_PROPS({ name: this.$route.name, props })
      },
    },
    eventFilters: {
      get () {
        return this.getDataTableFilters(this.$route.name)
      },
      set (filters) {
        this.SET_DATA_TABLE_FILTERS({ name: this.$route.name, filters })
        this.getEvents()
      },
    },
    finopsDateRange () {
      return {
        begin: $date.format($date.startOfMonth(new Date()), 'yyyy-MM-dd'),
        end: $date.format(new Date(), 'yyyy-MM-dd'),
      }
    },
    finopsDateLabel () {
      const [startDate, endDate] = ['begin', 'end'].map((it) => {
        const parsedDate = $date.parse(this.finopsDateRange[it], 'yyyy-MM-dd', new Date())
        return $date.format(parsedDate, 'MMM dd, yyyy')
      })
      return `${this.$t('currentMonth')}, ${startDate} - ${endDate}`
    },
    projectCanonicalsOfAllFavoriteKPIs () {
      return _(this.unfilteredKPIs).map('project_canonical').uniq().value()
    },
    kpiProjectFilterOptions () {
      return _.transform(this.projectCanonicalsOfAllFavoriteKPIs, (result, projectCanonical) => {
        if (projectCanonical !== this.kpiFilters.project) {
          result.push({
            label: projectCanonical,
            action: () => { this.SET_KPI_FILTERS_FAVORITES({ key: 'project', value: projectCanonical }) },
          })
        }
      }, [{
        label: this.$t('all'),
        action: () => { this.SET_KPI_FILTERS_FAVORITES({ key: 'project', value: null }) },
      }])
    },
    canSeeCloudCostManagement () {
      return this.$cycloid.permissions.canDisplay('GetCloudCostManagement')
    },
    canSeeProjects () {
      return this.$cycloid.permissions.canDisplay('GetProjects')
    },
    canSeeKPIs () {
      return this.$cycloid.permissions.canDisplay('GetKPIs')
    },
    canSeeEvents () {
      return this.$cycloid.permissions.canDisplay('GetEvents')
    },
    displayedKPIs () {
      const projectFilterCanonical = this.kpiFilters.project
      const sortedKPIs = _.sortBy(this.KPIs, ['project_canonical', 'name'])

      return projectFilterCanonical
        ? _.filter(sortedKPIs, ['project_canonical', projectFilterCanonical])
        : sortedKPIs
    },
  },
  watch: {
    orgCanonical: 'setup',
    kpiFilters: {
      async handler () {
        if (!this.isFirstLoadComplete.KPIs) return
        await this.getKPIs()
      },
      deep: true,
    },
  },
  created () {
    this.setup()
  },
  methods: {
    ...mapActions('organization', [
      'GET_ORG_SUMMARY',
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/project/kpi', [
      'GET_FAVORITE_KPIS',
    ]),
    ...mapActions('organization/cloudCostManagement', [
      'FETCH_DASHBOARD_DATA',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
      'SET_DATA_TABLE_PROPS',
    ]),
    ...mapMutations('organization', [
      'RESET_ORG_STATE',
    ]),
    ...mapMutations('organization/cloudCostManagement', [
      'SET_QUERY_FILTER',
    ]),
    ...mapMutations('organization/project/kpi', [
      'SET_KPI_FILTERS_FAVORITES',
    ]),
    async setup () {
      this.eventFilters = _.merge({}, this.eventFilters, this.getDefaultEventTimeFilters())
      this.eventProps = _.merge({}, this.eventProps, defaultEventProps, { filters: this.eventFilters })

      if (!this.isOrgSelected) return

      this.RESET_ORG_STATE('available.projects')

      this.SET_QUERY_FILTER({ key: 'begin', value: this.finopsDateRange.begin })
      this.SET_QUERY_FILTER({ key: 'end', value: this.finopsDateRange.end })
      this.SET_QUERY_FILTER({ key: 'granularity', value: 'day' })

      await this.$evaluateUserActions([
        'GetProjects',
        'GetKPIs',
        'GetEvents',
      ])

      await Promise.all([
        this.getOrgSummary(),
        this.getProjects(),
        this.getKPIs(),
        this.getEvents(),
        this.getFinopsData(),
      ])
    },
    async getProjects () {
      if (this.canSeeProjects) {
        await this.FETCH_AVAILABLE({
          keyPath: 'projects',
          extraParams: [{ favorite: true }],
        })
        this.favoriteProjects = _.cloneDeep(this.projects)
      }
      this.$set(this.isFirstLoadComplete, 'projects', true)
    },
    async getKPIs () {
      if (this.canSeeKPIs) await this.GET_FAVORITE_KPIS()
      if (_.isEmpty(this.unfilteredKPIs)) this.unfilteredKPIs = this.KPIs
      this.$set(this.isFirstLoadComplete, 'KPIs', true)
    },
    async getOrgSummary () {
      if (!this.loadingSummary) await this.GET_ORG_SUMMARY()
    },
    async getFinopsData () {
      if (this.canSeeCloudCostManagement) {
        await this.FETCH_DASHBOARD_DATA({ router: this.$router, persistQuery: false })
        this.$set(this.isFirstLoadComplete, 'finops', true)
      }
    },
    handleUnfavoriteStart () {
      this.$set(this.isEditEnabled, 'KPIs', false)
    },
    async handleUnfavoriteEnd () {
      await this.getKPIs()
      this.$set(this.isEditEnabled, 'KPIs', true)
    },
    async getEvents () {
      if (this.canSeeEvents) {
        await this.FETCH_AVAILABLE({
          keyPath: 'events',
          extraParams: [this.eventFilters],
        })
        this.$set(this.isFirstLoadComplete, 'events', true)
      }
    },
    getDefaultEventTimeFilters () {
      const nowTimestamp = Date.now()
      const sevenDaysAgoTimestamp = $date.subDays(nowTimestamp, 7)
      const begin = $date.format(sevenDaysAgoTimestamp, 'T')
      const end = $date.format(nowTimestamp, 'T')
      return { begin, end }
    },
    handleKpiDateTimeFilterChange (value) {
      this.SET_KPI_FILTERS_FAVORITES({ key: 'datePeriod', value })
    },
  },
  i18n: {
    messages: {
      en: {
        currentMonth: 'Current month',
        title: '@:routes.dashboard',
        favoriteProjects: 'Favorite projects',
        viewAllProjects: 'View all projects',
        finopsSectionTitle: '@:untranslated.finops & @:untranslated.greenops data',
        favoriteMetrics: 'Favorite metrics',
        projectName: '@:Project: {0}',
        addFavoriteKpi: 'Add metrics to your favorites by clicking on the star icon on any existing metric.',
      },
      es: {
        currentMonth: 'Mes actual',
        title: '@:routes.dashboard',
        favoriteProjects: 'Proyectos favoritos',
        viewAllProjects: 'Ver todos los proyectos',
        finopsSectionTitle: 'Datos @:untranslated.finops & @:untranslated.greenops',
        favoriteMetrics: 'Métricas favoritas',
        projectName: '@:Project: {0}',
        addFavoriteKpi: 'Agregue métricas a sus favoritos clikeando en la estrella en una métrica existente.',
      },
      fr: {
        currentMonth: 'Mois courrant',
        title: '@:routes.dashboard',
        favoriteProjects: 'Projets favoris',
        viewAllProjects: 'Voir tous les projets',
        finopsSectionTitle: 'Données @:untranslated.finops & @:untranslated.greenops',
        favoriteMetrics: 'Métriques favorites',
        projectName: '@:Project: {0}',
        addFavoriteKpi: `Ajoutez des métriques à vos favoris en cliquant sur l'icône étoile sur n'importe laquelle de vos métriques existantes`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~vuetify/src/components/VExpansionPanel/variables";

$events-section-header-heights: ( en: 44px, es: 64px, fr: 64px );

.dashboard-sidebar {
  display: grid;
  position: sticky;
  top: 76px;
  grid-template-rows: auto 1fr;
  height: 100%;
  max-height: calc(100vh - 130px - 24px); // screen height minus header and its margins
}

.v-expansion-panel {
  .theme--light.v-expansion-panels & {
    background-color: transparent;

    .v-icon {
      color: cy-get-color("primary");
    }
  }

  &-header {
    padding: 0;
    color: cy-get-color("primary");
  }

  &--active > &-header {
    min-height: $expansion-panel-header-min-height;
  }

  ::v-deep &-content__wrap {
    padding: 0;
  }
}

.grid {
  display: grid;
  grid-gap: 24px;

  &--favorite-projects {
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  }

  &--favorite-metrics {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
}

.kpi-widget-new {
  &__label {
    max-width: 260px;
    color: cy-get-color("primary", "light-2");
    line-height: $line-height-default;
  }
}

.cy-recent-events {
  max-width: 368px;
  min-height: 240px;
  max-height: 640px;
}

.bordered {
  border: 1px solid cy-get-color("primary", "light-4");
}

.cy-event-timeline {
  height: fit-content;
}
</style>
